.menu-main {
  /* border: 1px solid green; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 138px;
  /* background: url("../../asset/background3.jpg"); */
}

.menu {
  height: 50%;
  width: 100rem;
}

.menu img {
  height: 100%;
  width: 100%;
}
