.home-blog-sec {
  background-color: var(--color-background);
  width: 100%;
  height: 100%;
  padding-bottom: 128px;
  padding-top: 5rem;
}

.home-blog-heading-part {
  /* border: 1px solid red; */
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.home-blog-heading {
  color: var(--color-base);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.home-blog-sub-heading {
  color: var(--color-heading-txt-gray);
  font-weight: 500;
  font-size: 32px;
}

.home-blog-flex {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.home-blog-imgbox {
  max-width: 350px;
  height: 440px;
  position: relative;
}

.footer-up-card-bg {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12rem;
  border-top-right-radius: 12rem;
}

.home-blog-card-main {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  width: calc(100% - 60px);
  padding: 28px;
  position: absolute;
  left: 30px;
  bottom: -35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  text-align: center;
}

.home-blog-part {
  position: relative;
  cursor: pointer;
}

.home-blog-card-heading {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-base);
}

.home-blog-card-txt {
  color: var(--color-heading-txt-gray);
  font-size: 18px;
  line-height: 26px;
}

.home-blog-card-date {
  color: var(--color-txt-gray);
  font-size: 12px;
  text-transform: uppercase;
}

.home-blog-bg-color {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.5s ease-in-out;
}

.home-blog-part:hover .home-blog-bg-color {
  opacity: 0.6;
  background-color: #ec606c5d;
}

@media screen and (max-width: 1100px) {
  .home-blog-flex {
    gap: 60px;
    justify-content: center;
  }
}
