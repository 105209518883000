.abouta {
  /* border: 1px solid green; */
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
  background: url("../../asset/leaf_bg_img.webp");
}
