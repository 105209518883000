.our_team_sec{
    width: 100%;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #f7f2dc;

}

.our_team_con{
    width: 1140px;
    margin: 0 auto;
}

.our_team_flex{
    display: flex;
    /* align-items: center; */
    gap: 2rem;
    padding: 1.5rem;
    /* background-image: linear-gradient(to right, #062e05, #2f5a04); */
    background-color:  #062e05;

    /* background: linear-gradient(115.3deg, #ece3bc 7.4%, rgb(222, 172, 107) 103.1%); */
    /* background: linear-gradient(115.3deg, rgb(222, 172, 107) 7.4%, #ece3bc  103.1%); */
    border-radius: 8px;
}

.our_team_flex_part1{
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
}

.our_team_flex_part2{
    width: 2px;
    /* height: 100%; */
    /* height: 300px; */
    /* background-color: rgb(222, 172, 107); */
    background-color: #fff;
    /* background-color: #062e05; */
}

.our_team_flex_part3{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
}

.team_member_img_small{
    width: 75px;
    height: 75px;
    /* object-fit: cover; */
    border-radius: 8px;
    cursor: pointer;
    filter: grayscale(1);
}

.team_member_img_small_active{
    width: 75px;
    height: 75px;
    /* object-fit: cover; */
    filter: grayscale(0);

    border-radius: 8px;
    transform: scale(1.2);
    cursor: pointer;
    /* border: px solid rgb(222, 172, 107); */
}

.team_member_img_big{
    border-radius: 8px;
    width: 225px;
    /* max-height: 225px; */
    /* height: 29vh; */
}

.our_team_name{
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0.8rem;
    /* color: rgb(222, 172, 107); */
    color: #fff;
    /* color: var(--color-heading-txt-gray); */
    letter-spacing: 1.5px;
    text-transform: capitalize;

}

.our_team_desc{
    font-size: 16px;
    color: var(--color-heading-txt-gray);
    color: #fff;
    line-height: 1.5;
    font-weight: 500;
}

.our_team_desc p{
   margin-bottom: 10px;
}

.our_team_head{
    color: var(--color-heading-txt-gray);
    padding-bottom: 1rem;
}

@media screen and (max-width:1150px) {
    .our_team_con{
        max-width: 95%;
    }
}

@media screen and (max-width:920px) {
    .our_team_flex_part3{
        flex-direction: column;
        align-items: center;
    }

    .team_member_img_big{
        width: 200px;
    }
}

@media screen and (max-width:620px) {
    .our_team_flex{
        flex-direction: column;
    }

    .our_team_flex_part1{
        flex-direction: row;
        gap: 1.5rem;
        justify-content: center;
    }
}


/* Add chat GPT */


.our_team_flex_part3 {
    position: relative;
}

.team_member_img_small {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.team_member_img_small.active {
    transform: scale(1.1); /* Enlarge active image */
}

.our_team_sub_part1 img,
.our_team_sub_part2 p {
    opacity: 1;
    transition: opacity 0.3s ease; /* Fade in/out */
}

.fade img,
.fade p {
    opacity: 0; /* Fading out */
}


