@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Outfit:wght@100..900&family=Playwrite+AR:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Outfit:wght@100..900&family=Playwrite+AR:wght@100..400&family=Playwrite+BE+VLG:wght@100..400&family=Playwrite+DK+Loopet:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.home-services-sec {
  /* border: 1px solid orange; */
  width: 100%;
  padding-top: 96px;
  padding-bottom: 26px;
  background-color: #f7f2dc;
  margin-bottom: 10px;
}

.home-services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.home-services-heading {
  /* padding: 3rem; */
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 25px; */
  /* justify-content: center; */
  /* align-items: center; */
  margin-bottom: 6rem;
  text-align: center;
  /* max-width: 650px; */
  /* margin: 0 auto; */
  margin-bottom: 40px;
}

.home-services-heading-img {
  width: 100px;
  height: 100px;
}

.home-services-head-txt {
  color: #939393;
  font-size: 16px;
  margin-bottom: 10px;
}

.home-services-head-big-txt {
  /* font-family: "Playwrite Argentina" !important; */
  /* font-family: "Playwrite DK Loopet", sans-serif !important; */
  color: var(--color-heading-txt-gray);
  font-size: 32px;
  /* font-weight: 500; */
  /* text-align: center; */
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .home-services-box {
    width: 100%;
  }

  .home-services-head-big-txt {
    font-size: 28px;
  }

  .home-services-head-txt,
  .home-services-head-txt2 {
    font-size: 16px;
  }
}
