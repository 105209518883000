.about-sec {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 96px;
  background-color: #fcf9f7;
}

.about-heading-part {
  max-width: 650px;
  margin: 0 auto;
  /* border: 1px solid var(--color-base); */
  color: var(--color-heading-txt-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  margin-bottom: 40px;
}

.about-heading {
  color: var(--color-heading-txt-gray);
  font-weight: 500;
  font-size: 22px;
  /* color: rgb(145, 20, 62); */
  color: var(--color-heading-txt-gray);
}

.about-txt {
  color: var(--color-txt-gray);
  text-align: justify;
  margin-bottom: 20px;
}

.about-txt-side-heading {
  color: var(--color-heading-txt-gray);
  font-weight: 500;
}

@media screen and (max-width: 1170px) {
  .resp-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}
