.contact-sec {
  /* border: 1px solid red; */
  background-color: var(--color-background);
  color: var(--color-heading-txt-gray);
  /* width: 100%; */
  /* height: 100%; */
  padding-top: 100px;
  margin-top: 200px;
  padding-bottom: 3rem;
  /* margin-bottom: 96px; */
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  gap: 2rem;
}

.contact-flex {
  /* border: 1px solid orange; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* gap: 1rem; */
}

.contact-flex-part1 {
  /* border: 1px solid red; */
  /* padding: 0.5rem; */
  /* width: 525px; */
}

.contact-flex-part2 {
  /* border: 1px solid green; */
  /* width: 425px; */
  /* height: 500px; */
  padding: 0.5rem;
  width: 600px;
  height: 320px;
  /* display: flex; */
  /* flex-direction: column; */
  gap: 15px;
  padding-bottom: 2rem;
}

.contact-flex-part2 img {
  width: 425px;
  height: 500px;
}

.contact-head {
  font-weight: 500;
  color: var(--color-heading-txt-gray);
  font-size: 32px;
}

.contact-head-part {
  /* border: 1px solid red; */
  max-width: 350px;
  /* margin-left: 240px; */
  /* margin: 0 auto; */
  margin-bottom: 50px;
}

.contact-hour-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Conatct  */
.contact_sec {
  background: #fff;
}

.contact {
  display: flex;
  flex-direction: row;
  padding: 5%;
  gap: 100px;
}

.contact-us-heading {
  color: #0072b1;
  text-transform: uppercase;
}

.c-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.our-service-item-name {
  font-size: 18px;
}

.our-service-item-desc {
  font-size: 18px;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-form {
  display: flex;
  flex-direction: column;
}

.c-txt {
  border: 1px solid gray;
  height: 40px;
}

.c-lbl {
  margin-bottom: 5px;
  margin-top: 20px;
}

.c-msg-txt {
  border: 1px solid gray;
  /* height: 40px; */
  height: 160px;
}

.quick-heading {
  color: #0072b1;
  text-transform: uppercase;
}

.con-info-flex {
  display: flex;
  /* align-items: center; */
  gap: 8px;
  margin-bottom: 10px;
}

.con-info-icon {
  height: 20px;
  width: 20px;
  stroke: #000;
  /* align-self: end; */
}

.c-form-btn {
  margin-top: 20px;
  height: 40px;
  background: #007bff;
  color: #fff;
  font-weight: 400;
  font-size: 120%;
  border: none;
  cursor: pointer;
  padding: 0.4rem 1rem;
  text-align: center;
  transition: 0.5s;
}

.c-form-btn:hover {
  color: #fff;
  background: #007bff;
  border: #007bff;
}

.c-txt[type="text"],
.c-msg-txt[type="text"] {
  padding: 0 10px 0 10px;
  font-size: 120%;
  font-weight: 600;
}

.c-ulbl {
  margin-bottom: 0px;
  font-size: 100%;
  color: #000;
  font-weight: 900;
}

.c-dlbl {
  margin-top: 0px;
  font-size: 120%;
  font-weight: 400;
  color: #515151;
}

/* .mt01 {
    margin-top: 40px !important;
} */

.indus {
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.indus-title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #515151;
}

.indus-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.indus-item {
  padding: 1% 1.5%;
  color: #fff;
  background: #515151;
  border-radius: 100px;
  font-weight: 700;
}

.border-bottom {
  height: 2px;
  background: var(--color-pink);
  width: 30%;
  align-self: center;
  margin: 10px auto;
}

@media screen and (max-width: 1180px) {
  .contact-flex {
    padding-left: 15px;
    /* padding-right: 15px; */
  }

  .contact-head-part {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1028px) {
  .contact-sec {
    padding: 4rem 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 275px;
  }
}

@media screen and (max-width: 768px) {
  .contact-flex {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .contact-head-part {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 615px) {
  .contact-flex-part2 {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .our-service-item-name {
    font-size: 15px;
  }
  .our-service-item-desc {
    font-size: 15px;
  }
}
