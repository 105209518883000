.home-packages-sec {
    width: 100%;
    background-color: #FBF2F0;
    margin-top: 96px;
    position: relative;
    height: 800px;
}

.home-packages-flex {
    display: flex;
    gap: 25px;
    padding-top: 96px;
    width: 100%;

}

.home-packages-left-side-big-txt {
    line-height: 225px;
    letter-spacing: -3px;
    font-weight: 600;
    color: var(--color-white);
    font-size: 255px;
    position: absolute;
    left: -207px;
    top: 240px;
    transform: rotate(90deg);
}

.home-packages-slider-img-inner-box {
    position: absolute;
    left: 10%;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.home-packages-slider-img {
    /* position: relative; */
    width: 350px !important;
    height: 465px !important;
    border-radius: 4px;
}

.home-packages-slider-img-inner-txt {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: var(--color-white);
}

.home-packages-sliderimg-inner-btn {
    padding: 5px 15px 5px 15px;
    background: var(--color-base);
    border-radius: 2px;
    transition: all 0.5s ease-in-out;
    color: var(--color-white);
    font-style: 500;
    font-size: 12px;
    line-height: 16px;
}

.home-packages-slider-img-inner-txt {
    font-size: 20px;
    line-height: 28px;
    color: var(--color-white);
    font-weight: 500;
}

.home-packages-slider-img-inner-btn-white {
    background: var(--color-white);
    color: var(--color-black);
    transition: all 0.5s ease-in-out;
    font-size: 10px;
    padding: 7px 18px;
}

.home-packages-slider-img-inner-btn-white:hover {
    background: transparent;
    border: 1px solid #fff;
    color: var(--color-white);
}

.home-packages-sec-part-flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 200px;
    min-width: 350px;
    margin-top: 96px;
}

.home-packages-sec-part-heading {
    color: var(--color-base);
}

.home-packages-sec-part-sub-heading {
    color: var(--color-heading-txt-gray);
    font-weight: 500;
    font-size: 32px;
}

.home-packages-slider-img-inner-txt {
    font-size: 16px;
    color: var(--color-txt-gray);
    line-height: 1.5rem;
}

.home-packages-sec-part-txt {
    color: var(--color-txt-gray);
    font-size: 16px;
    line-height: 1.5rem;
}

.slick-slider {
    /* max-width: 600px !important; */
}

.home-packages-third {
    width: -webkit-fill-available;
    overflow: hidden;
}

.slick-initialized .slick-slide {
    /* width: 365px !important; */
}