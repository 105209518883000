.home-fac-sec {
  width: 100%;
  /* padding-top: 96px; */
  /* padding-bottom: 96px; */
  margin-top: 138px;
  /* margin-bottom: 100px; */
  padding: 2rem;
  /* background-color: var(--color-background); */
  background-image: url("../../asset/background5.jpg");
}

.home-fac-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-fac-part1 {
  max-width: 525px;
}

.home-fac-part2 {
  /* border: 1px solid red; */
  max-width: 630px;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 25px; */
}

.home-fac-part2-inner-flex {
  display: flex;
  /* align-items: center; */
  gap: 25px;
}

.home-fac-list-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-fac-side-img {
  width: 100%;
  height: 100%;
}

.facility-small-img {
  width: 145px;
  height: 150px;
  padding: 0.1rem;
  /* border: 1px solid rgb(76, 107, 40); */
  border: 1px solid white;
  border-radius: 50%;
}

.home-fac-list-head {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-heading-txt-gray);
}

.home-fac-list-txt {
  color: var(--color-dark-gray);
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.Facility_part2_main {
  background-color: #fbf9f0;
  /* margin: 0 auto;
  max-width: 1140px; */
  padding: 2.5rem 2.5rem 2.5rem 0rem;
  /* margin-bottom: 96px; */
  border-radius: 6px;
  /* padding-top: 96px;
  padding-bottom: 96px; */
  display: flex;
  gap: 2.5rem;
  align-items: center;
  /* margin: 2rem; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Facility_part2_img_main {
  height: 600px;
  width: 50%;
}

.Facility_part2_inner2 {
  width: 50%;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: 1rem;
}
.Facility_part2_inner2_content {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
@media screen and (max-width: 1170px) {
  .home-fac-flex {
    padding-left: 15px;
    padding-right: 15px;
  }
  .Facility_part2_main {
    flex-wrap: wrap;
    /* margin: 2rem; */
    /* margin-bottom: 96px; */
    display: flex;
    justify-content: center;
  }
  .Facility_part2_inner2 {
    padding: 1rem;
    width: 100%;
  }

  .Facility_part2_img_main {
    height: 500px;
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .home-fac-flex {
    flex-direction: column;
    gap: 25px;
  }

  .home-fac-part2-inner-flex {
    flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .Facility_part2_img_main {
    height: 350px;
  }
}
@media screen and (max-width: 450px) {
  .facility-small-img {
    width: 115px;
    height: 115px;
    border-radius: 115px;
  }

  .Facility_part2_inner2 {
    /* margin: 1rem; */
    /* padding: 1rem; */
  }
}
