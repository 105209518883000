.our-menu-list-sec {
  width: 100%;
  height: 100%;
  /* margin-top: 200px;
  margin-bottom: 96px; */
  background-color: #fcf9f7;
  padding-top: 50px;
  padding-bottom: 96px;
}

.our-service-menu-heading-part {
  max-width: 650px;
  margin: 0 auto;
  /* background-color: var(--color-base); */
  /* background-color: var(--color-heading-txt-gray); */
  /* border: 2px solid #ffb103; */
  color: var(--color-heading-txt-gray);
  /* color: var(--color-white); */
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.our-service-menu-heading-main {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.our-service-menu-heading-txt {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

.our-menu-list-felx {
  /* display: flex; */
  column-gap: 60px;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: center;
  row-gap: 60px;
}

.our-service-menu-part {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* width: 625px; */
}

.our-service-menu-item-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.our-service-menu-item-box-main {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin: 0.5rem; */
}

.our-service-item-name {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-heading-txt-gray);
}

.our-service-item-price {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-heading-txt-gray);
}

.our-service-item-desc {
  font-size: 16px;
  color: var(--color-txt-gray);
  /* line-height: 1.5rem; */
  /* text-align: justify; */
  text-align: left;
}

.our-service-menu-type-box {
  /* border: 1px solid var(--color-base); */
  /* border: 1px solid #ffb103; */
  /* background-color: var(--color-heading-txt-gray); */
  background-color: rgb(217, 163, 93);
  color: white;
  width: 100%;
  margin-top: 2rem;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.facial-type {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.our-service-menu-part-last {
  /* max-width: 650px; */
  margin: 0 auto;
  margin-top: 40px;
}

@media screen and (max-width: 1170px) {
  .our-menu-list-felx {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1110px) {
  .our-menu-list-felx {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 650px) {
  .our-service-menu-heading-part {
    max-width: 450px;
  }

  .our-service-menu-heading-main {
    font-size: 32px;
  }
}

@media screen and (max-width: 530px) {
  .our-service-menu-heading-part {
    max-width: 350px;
  }

  .our-service-menu-heading-main {
    font-size: 26px;
  }

  .our-service-menu-part {
    /* width: 335px; */
  }
}
