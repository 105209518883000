.consultation-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    margin-top: 11rem;
    padding-bottom: 5rem;
}

.text_cons{
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    word-spacing: 1px;
}

.head_text_cons{
    color: var(--color-heading-txt-gray);
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 20px;
}

.cons_lists {
    list-style: none;
    padding: 0;
}

.cons_list {
    margin-bottom: 15px;
    font-size: 16px;
    word-spacing: 1px;
    line-height: 1.5;
}

.cons_list_bold {
    color: var(--color-heading-txt-gray);
    font-weight: 500;
}

.book-consultation-btn {
    padding: 10px 20px;
    background-color: var(--color-heading-txt-gray);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.book-consultation-btn:hover {
    background-color: var(--color-white);
    border: 2px solid var(--color-heading-txt-gray);
    color: var(--color-heading-txt-gray);
    font-weight: 600;
}

.intro-section, .benefits-section, .consultant-section, .call-to-action {
    margin-bottom: 40px;
}

.cons_last_sec{
    margin-bottom: 60px;
}


@media screen and (max-width:450px) {
    .head_text_cons{
        font-size: 18px;
    }
}


/* Landing page Modal CSS */

.signup_modal_close{
    position: absolute;
    right: 10px;
    top: 10ox;
}

.landing_modal_line{
    color: var(--color-heading-txt-gray);
    font-weight: 600;
    font-size: 22px;
    line-height: 1.2;
    max-width: 325px;
    text-align: center;
    margin-bottom: 1.5rem;
}

.modal_bg_color{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 21%);
    width: 100%;
    height: 100%;
}


@media screen and (max-width:555px) {
    .model_sizing{
        padding: 2.1rem !important;
    }

    .landing_modal_line{
        font-size: 18px;
    }
}

@keyframes slideDown {
    from {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  
  .modal-content {
    animation: slideDown 0.5s ease-out;
  }

  @keyframes slideUp {
    from {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    to {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
  }
  
  .modal-content {
    animation: slideDown 0.5s ease-out;
  }
  
  .modal-close-animation {
    animation: slideUp 0.5s ease-out;
  }