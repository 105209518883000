.home-testi-sec {
  background-color: #fbf9f0;
  width: 100%;
  height: 100%;
  margin-top: 135px;
  padding-bottom: 96px;
  padding-top: 80px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.home-testi-flex {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.home-test-part1 {
  background-color: #f6f3df;
  padding: 3rem;
  /* max-width: 400px; */
  /* height: 20rem; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home-test-part2 {
  /* border: 1px solid green; */
  width: 650px;
  padding: 0 1rem;
}

.home-testi-part1-heading {
  color: var(--color-base);
  font-weight: 500;
  font-size: 16px;
}

.home-test-part1-txt {
  color: var(--color-heading-txt-gray);
  font-size: 32px;
  font-weight: 500;
  line-height: 2.5rem;
}

.home-testi-imgbox {
  max-width: 180px;
  max-height: 180px;
  border-radius: 180px;
}

.home-testi-img {
  width: 100%;
  height: 100%;
  border-radius: 5%;
}

.home-testi-inner-flex {
  display: flex;
  /* align-items: center; */
  gap: 35px;
}

.home-testi-txt {
  color: var(--color-txt-gray);
  font-size: 16px;
  line-height: 2rem;
  margin-bottom: 20px;
}

.home-testi-auth {
  color: var(--color-heading-txt-gray);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.home-testi-auth-desg {
  color: var(--color-txt-gray);
  font-size: 12px;
  text-transform: uppercase;
}

/* Media Query */

@media screen and (max-width: 1170px) {
  .home-testi-flex {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .home-testi-flex {
    flex-direction: column;
    gap: 25px;
  }
}

@media screen and (max-width: 650px) {
  .home-testi-inner-flex {
    flex-direction: column;
    gap: 35px;
    width: 100%;
  }

  .home-test-part2 {
    width: 450px;
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 450px) {
  .home-test-part2 {
    width: 100%;
    padding: 0 0.5rem;
  }

  .home-testi-txt {
    font-size: 14px;
  }

  .home-test-part1-txt {
    font-size: 26px;
  }
}
