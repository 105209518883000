@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Outfit:wght@100..900&family=Playwrite+AR:wght@100..400&family=Playwrite+BE+VLG:wght@100..400&family=Playwrite+DK+Loopet:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.home-services-box-flex {
  /* border: 1px solid red; */
  margin-bottom: 150px;
  /* background-color: #f4edeb; */
  /* border-radius: 10px; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  /* justify-content: space-around; */
  /* gap: 10px; */
  /* align-items: center; */
}

.home-services-main {
  background-color: #f9f8f2;
  padding: 3rem;
  margin: 0rem 0 12rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.home-services-card {
  position: relative;
  /* width: 300px; */
  width: 266px;
  /* height: 390px; */
  height: 490px;
  margin: 12px;
}

.home-services-box .home-service-card .home-service-face {
  position: absolute;
  width: 100%;
  height: 70%;
  backface-visibility: hidden;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 5px;
}

.home-service-box .home-service-card .home-service-front img {
  position: absolute;
  width: 350px;
  height: 515px;
  /* object-fit: fill; */
}

.home-services-box-inner {
  padding: 0 0rem 0 0;
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* justify-content: center; */
  /* gap: 25px; */
}

.home-services-icon {
  /* border: 1px solid rgb(163, 162, 162); */
  /* padding: 0.1rem; */
  width: 280px;
  height: 270px;
  /* margin-bottom: 20px; */
  object-fit: cover;
  border-radius: 5px;
}

.home-service-card-frint-txt-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, transparent 30%, #232323);
  opacity: 0.7;
  color: var(--color-white);
}

.home-service-box-txt {
  /* border: 1px solid red; */
  padding: 0.5rem;
  color: var(--color-txt-gray);
  font-size: 16px;
  /* text-align: inherit; */
  /* text-align: justify;
  text-justify: distribute;*/
  line-height: 1.5rem;
  text-align: justify;
  /* width: 240px; */
  /* word-break: break-all; */
}

.home-services-box-heading {
  padding: 0.5rem;
  color: var(--color-heading-txt-gray);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.home-services-head-txt2 {
  padding: 0.5rem;
  color: var(--color-txt-gray);
  font-size: 16px;
  text-align: center;
  line-height: 1.5rem;
  position: relative;
}

.btn-readmore {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  padding: 0;
}

.btn-readmore:hover {
  text-decoration: underline;
}

@media screen and (max-width: 380px) {
  .home-services-icon {
    width: 100%;
  }
}

@media screen and (max-width: 620px) {
  .home-services-box-inner {
    display: flex;
    flex-direction: column;
  }
}
